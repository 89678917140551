import './Styles/global.less';
import './Files';

import './Setup';
import './Components';

import { default as context } from '@truckdown/systems';
import { IAppUserContext } from './IAppUserContext';

export type { IAppUserContext };
export type { IIconLoader } from './IIconLoader';
export type { INavItem } from './INavItem';
export default context as IAppUserContext;